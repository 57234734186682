import { keyframes } from '@mui/material';

export const rotation = keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const headerStickyVariant = {
  exit: { zIndex: -1 },
  initial: {
    opacity: 0,
    y: -10,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

export const showMainMenuTabsHeightVariant = {
  hidden: {
    height: 0,
  },
  visible: {
    height: 'auto',
    transition: {
      duration: 0.45,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};
export const showMainMenuTabsOpacityVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.15,
    },
  },
};

export const ecosystemMenuVariant = {
  open: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: {
        duration: 0.3,
        type: 'tween',
      },
      opacity: {
        duration: 0.4,
        type: 'tween',
        delay: 0.2,
      },
    },
  },
  collapsed: {
    opacity: 0,
    height: 0,
    transition: {
      height: {
        duration: 0.3,
        type: 'tween',
      },
      opacity: {
        duration: 0.15,
        type: 'tween',
      },
    },
  },
};

export const searchMenuVariant = {
  open: (custom) => ({
    opacity: 1,
    height: custom.height,
    minHeight: custom.minHeight,
    transition: {
      height: {
        duration: 0.3,
        type: 'tween',
      },
      opacity: {
        duration: 0.4,
        type: 'tween',
        delay: 0.2,
      },
    },
  }),
  collapsed: {
    opacity: 0,
    height: 0,
    transition: {
      height: {
        duration: 0.3,
        type: 'tween',
      },
      opacity: {
        duration: 0.15,
        type: 'tween',
      },
    },
  },
};

export const fabVariants = {
  open: {
    ...ecosystemMenuVariant.open,
    transition: {
      ...ecosystemMenuVariant.open.transition,
      width: {
        duration: 0.1,
        type: 'tween',
      },
    },
    width: 'auto',
  },
  collapsed: {
    ...ecosystemMenuVariant.collapsed,
    transition: {
      ...ecosystemMenuVariant.collapsed.transition,
      width: {
        duration: 1,
        type: 'tween',
      },
    },
    width: 0,
  },
};
