import React, { FC, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import theme from '@mui/theme';
import { HeaderContext } from '@organisms/Header/AltHeader/Header.context';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import GridContainer from '@atoms/GridContainer/GridContainer';
import LogoHeader from '@atoms/LogoHeader/LogoHeader';
import HeaderBox from '@organisms/Header/AltHeader/HeaderContent/shared/HeaderBox';
import headerMobileShape from '@assets/header/header_mobile_shape.svg';
import { useColors } from '@hooks/useColors';
import dynamic from 'next/dynamic';

const HeaderMobileContent = dynamic(
  () => import('@organisms/Header/AltHeader/HeaderContent/HeaderMobile/HeaderMobileContent')
);

type Props = { show: boolean };

const HeaderMobile: FC<Props> = ({ show }) => {
  const {
    headerData: { colorVariant },
    isSticky,
    setIsMobileMenuOpen,
    onMobileMenuButtonClick,
  } = useContext(HeaderContext);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setIsMobileMenuOpen(false);
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { backgroundColor } = useColors(colorVariant);

  return (
    <HeaderBox show={show} isSticky={isSticky}>
      <GridContainer
        sx={{
          backgroundImage: `url(${headerMobileShape.src})`,
          backgroundColor,
          backgroundPositionX: 'center',
          backgroundPositionY: 'top',
          backgroundRepeat: 'no-repeat',
          py: theme.spacing(8),
        }}
      >
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" zIndex={100} position="relative">
          <LogoHeader href="/" />

          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={onMobileMenuButtonClick}
            sx={{ position: 'absolute', right: 0 }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </GridContainer>

      {show ? <HeaderMobileContent show={show} /> : null}
    </HeaderBox>
  );
};

export default HeaderMobile;
