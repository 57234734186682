import { useMemo } from 'react';
import { ColorVariant } from '@model/types';

export const useColors = (colorVariant: ColorVariant = 'light') => {
  const backgroundColor = useMemo(() => {
    return colorVariant === 'light' ? 'common.white' : 'common.black';
  }, [colorVariant]);

  const textColor = useMemo(() => {
    return colorVariant === 'light' ? 'common.black' : 'common.white';
  }, [colorVariant]);

  return {
    backgroundColor,
    textColor,
  };
};
