import styles from '@organisms/Header/Header.module.scss';
import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { HeaderCommonProps } from '@organisms/Header/Header.props';

const HeaderBox = ({ children, isSticky, show }: PropsWithChildren<HeaderCommonProps & { show: boolean }>) => {
  return (
    <Box
      component="header"
      width="100%"
      zIndex={999}
      top={0}
      position={!isSticky ? 'relative' : 'sticky'}
      className={isSticky && styles.isSticky}
      display={show ? 'block' : 'none'}
    >
      {children}
    </Box>
  );
};

export default HeaderBox;
