import Link from 'next/link';
import { motion } from 'framer-motion';

import logoHeader from '@assets/header/logo-header.svg';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LogoHeaderProps } from './LogoHeader.props';

import styles from './LogoHeader.module.scss';
import { headerStickyVariant } from '@mui/mui-animations';

const LogoHeader = ({ isSticky = false, href, color = 'primary.main', ...linkProps }: LogoHeaderProps) => {
  const headerMediumBreakpoint = useMediaQuery(`(min-width: 1200px) and (max-width: 1400px)`);

  const headerBoxClasses = `${styles.headerBox} ${isSticky ? styles.headerSticky : ''}`;

  return (
    <motion.div variants={headerStickyVariant} initial="initial" animate="animate">
      <Link
        itemScope
        itemType="http://schema.org/Organization"
        className={styles.LogoHeader}
        href={href}
        prefetch={false}
        {...linkProps}
      >
        <Box component={'span'} className={headerBoxClasses} color={color}>
          <svg className={styles.svg}>
            {isSticky || headerMediumBreakpoint ? (
              <use xlinkHref={`${logoHeader.src || logoHeader}#logo-header-condensed`} />
            ) : (
              <use xlinkHref={`${logoHeader.src || logoHeader}#logo-header`} />
            )}
          </svg>
        </Box>
      </Link>
    </motion.div>
  );
};

export default LogoHeader;
